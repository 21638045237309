import React from "react"

import pdf0 from "../../static/COMO EDUCAR  A TUS HIJOS.pdf"
import pdf1 from "../../static/EL SOLDADO DE CHOCOLATE.pdf"
import pdf2 from "../../static/ÉSTOS TIEMPOS DEMANDAN UNA CONFIANZA ESPECIAL.pdf"
import pdf3 from "../../static/OREN POR AVIVAMENTO.pdf"
import pdf4 from "../../static/Quince consejos para la educación de los hijos.pdf"
import pdf5 from "../../static/1.pdf"

export default function Texts() {
  return (
    <div className="mt-48 px-16 ">
      <table className="border-collapse  w-full">
        <thead>
          <tr className="border-b">
            <th className="p-3 text-center font-bold uppercase bg-gray-200 text-gray-600  hidden lg:table-cell rounded-tl-md">
              Nombre
            </th>
            <th className="p-3 text-center font-bold uppercase bg-gray-200 text-gray-600  hidden lg:table-cell">
              Autor
            </th>

            <th className="p-3 text-center font-bold uppercase bg-gray-200 text-gray-600  hidden lg:table-cell rounded-tr-md">
              Descargar
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Nombre
              </span>
              COMO EDUCAR A TUS HIJOS
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Autor
              </span>
              Charles G. Finney
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 border-b text-center block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                descargar
              </span>
              <a
                href={pdf0}
                className="rounded bg-green-400 py-1 px-3 text-xs font-bold text-white"
                download
              >
                Click para descargar
              </a>
            </td>
          </tr>
          <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Nombre
              </span>
              QUINCE CONSEJOS PARA LA EDUCACIÓN DE LOS HIJOS
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Autor
              </span>
              Susanna Annesley de Wesley
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 border-b text-center block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                descargar
              </span>
              <a
                href={pdf1}
                className="rounded bg-green-400 py-1 px-3 text-xs font-bold text-white"
                download
              >
                Click para descargar
              </a>
            </td>
          </tr>
          <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Nombre
              </span>
              EL SOLDADO DE CHOCOLATE
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Autor
              </span>
              Charles Thomas Studd
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 border-b text-center block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                descargar
              </span>
              <a
                href={pdf2}
                className="rounded bg-green-400 py-1 px-3 text-xs font-bold text-white"
                download
              >
                Click para descargar
              </a>
            </td>
          </tr>
          <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Nombre
              </span>
              ÉSTOS TIEMPOS DEMANDAN UNA CONFIANZA ESPECIAL
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Autor
              </span>
              David Wilkerson
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 border-b text-center block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                descargar
              </span>
              <a
                href={pdf3}
                className="rounded bg-green-400 py-1 px-3 text-xs font-bold text-white"
                download
              >
                Click para descargar
              </a>
            </td>
          </tr>
          <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Nombre
              </span>
              OREN POR AVIVAMENTO
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Autor
              </span>
              Charles G. Finney
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 border-b text-center block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                descargar
              </span>
              <a
                href={pdf4}
                className="rounded bg-green-400 py-1 px-3 text-xs font-bold text-white"
                download
              >
                Click para descargar
              </a>
            </td>
          </tr>
          <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Nombre
              </span>
              EL TABERNÁCULO DE DAVID
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border-b block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                Autor
              </span>
              Luis Peralta
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 border-b text-center block lg:table-cell relative lg:static">
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                descargar
              </span>
              <a
                href={pdf5}
                className="rounded bg-green-400 py-1 px-3 text-xs font-bold text-white"
                download
              >
                Click para descargar
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
