import React from "react"
import SEO from "../components/seo"
import "../css/style.css"
import Header from "../components/Header"
import Landing from "../components/Landing"
import Section4 from "../components/Section4"
import WhatYouCanFindInPage from "../components/WhatYouCanFindInPage"
import Texts from "../components/Texts"

function index() {
  return (
    <>
      <SEO
        lang="es"
        title="Jovenes Discipulos"
        description="La página oficial de Jóvenes Discípulos"
      />
      <body>
        <div class="animsition">
          <div class="click-capture"></div>
          <Header />
          <div class="copy-bottom white boxed">© Jóvenes Discípulos</div>
          <div class="social-list social-list-bottom boxed" target="_blank">
            <a
              href="https://www.instagram.com/jovenes.discipulos/"
              class="icon ion-social-instagram"
              target="_blank"
            ></a>
            <a
              href="https://twitter.com/j_discipulos"
              class="icon ion-social-twitter"
              target="_blank"
            ></a>
            <a
              href="https://www.facebook.com/jovenesdiscipulosmx"
              class="icon ion-social-facebook"
              target="_blank"
            ></a>
            <a
              href="https://www.youtube.com/user/jovenesdiscipulos"
              class="icon ion-social-youtube"
              target="_blank"
            ></a>
          </div>
          <div class="pagepiling">
            <Texts />
          </div>
        </div>
      </body>
    </>
  )
}

export default index
